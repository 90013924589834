export const ADD_TO_CART_POPUP_ID_PREFIX = 'ADD_TO_CART_POPUP_';
export const ADD_TO_CART_LIMITED = 'ADD_TO_CART_LIMITED_POPUP_';
export const newAddToCartTemplates = [
    'Pim Armadi',
    'Pim Camere Complete',
    'Pim Cucine bloccate',
    'Pim Bagno',
];
export const ALERT_POPUP = 'ALERT_POPUP';
export const ALERT_POPUP_CONDIVIDI = 'ALERT_POPUP_CONDIVIDI';
export const ALERT_POPUP_COPIATO = 'ALERT_POPUP_COPIATO';
