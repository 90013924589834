/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';

import AddToCartPopup from 'Component/AddToCartPopup';
import { ADD_TO_CART_LIMITED } from 'Component/AddToCartPopup/AddToCartPopup.config';
import { waitForElm } from 'Component/Config';
import Popup from 'Component/Popup';
import { CARTE_PAGE_WAREHOUSE_POPUP_ADO, CATEGORY_ID_ANGOLO_DELLE_OCCASIONI } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import {
    AddToCartComponent as SourceAddToCart,
} from 'SourceComponent/AddToCart/AddToCart.component';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { isMobile } from 'Util/mcMobileCheck';

import './AddToCart.override.style';

/** @namespace Pwa/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCart {
    static propTypes = {
        ...SourceAddToCart.propTypes,
        added: PropTypes.boolean,
    };

    renderPopupADOWarehouse() {
        const { showPopup } = this.props;
        const description = __('Inserisci la città o il CAP per calcolare la disponibilità');

        return (
            <Popup
              customClass="CartPage-ADO-Warehouse"
              id={ CARTE_PAGE_WAREHOUSE_POPUP_ADO }
            >
                <div block="CartPage" elem="ADO Warehouse">
                    <p className="title">{ __('ATTENZIONE') }</p>
                    <p className="description" dangerouslySetInnerHTML={ { __html: description } } />
                    <button
                      onClick={ async () => {
                          const capBox = await waitForElm('.ProductCapBox-Input');
                          const capTitle = await waitForElm('.ProductCapBox-Title');
                          capBox.classList.add('required');
                          showPopup();

                          if (isMobile()) {
                              setTimeout(() => capTitle.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'start',
                                  inline: 'start',
                              }), 50);
                          }
                      } }
                    >
                        { __('OK, HO CAPITO') }
                    </button>
                </div>
            </Popup>
        );
    }

    renderPlaceholder() {
        const {
            product: {
                media_gallery_entries,
            },
        } = this.props;

        const placeholderVideo = media_gallery_entries?.filter((media) => media.types.includes('placeholder_video'));

        if (placeholderVideo?.length > 0) {
            return (
                <img src={ placeholderVideo[0]?.base.url } alt="Placeholder Video" />
            );
        }

        return null;
    }

    render() {
        const {
            product, product: { sku }, fromPopup, added, fromDC, fromPDP, showPopup,
        } = this.props;

        const {
            mix,
            isDisabled,
            product: { type_id },
            isAdding,
            handleButtonClick,
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        const loadingClass = isAdding ? 'AddToCart_isLoading' : '';

        const isADO = product?.categories?.find((el) => el.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;

        return (
            <>
                <button
                  onClick={ (event) => {
                      const warehouse = BrowserDatabase.getItem('mc_warehouse')?.value?.code;

                      if (isADO && !warehouse && fromPDP) {
                          showPopup(CARTE_PAGE_WAREHOUSE_POPUP_ADO);
                      } else {
                          handleButtonClick(event);
                      }
                  } }
                  block="Button AddToCart"
                  mix={ mix }
                  className={ loadingClass }
                  sku={ sku }
                    // mods={ loadingClass }
                  disabled={ isDisabled || isAdding }
                >
                    <span>{ isAdding ? __('Adding...') : __('Add to cart') }</span>
                    <span>{ !isAdding ? __('Add to cart') : __('Adding...') }</span>
                </button>
                { (added && fromPopup) && (
                    <p className="added-to-cart">
                        <i className="fa fa-check" aria-hidden="true" />
                        <span>{ __('Aggiunto al carrello') }</span>
                    </p>
                ) }
                { fromPDP && isADO && this.renderPopupADOWarehouse() }
                <Popup
                  customClass="limitedItem"
                  id={ `${ADD_TO_CART_LIMITED}_${sku}` }
                >
                    { __('Hai raggiunto il limite massimo di pezzi acquistabili per questo prodotto') }
                </Popup>
                { !fromPopup && (<AddToCartPopup product={ product } fromDC={ fromDC } />) }
            </>
        );
    }
}

export default AddToCartComponent;
